import React from 'react';
import '@wpa/styles/less/ant.less';
import '@wpa/styles/scss/main.scss';
import './App.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faFacebook as fabFacebook,
	faTwitter as fabTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { Route, Switch } from 'react-router-dom';

import routes from './routes.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Layout, Row, Col} from 'antd';

require('@wpa/feathers-client');

const { Content } = Layout;

library.add(
	fabTwitter,
	fabFacebook,
);

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
	return (
	  <Route
		path={route.path}
		render={props => (
		  // pass the sub-routes down to keep nesting
		  <route.component {...props} routes={route.routes} />
		)}
	  />
	);
  }

console.log('routes', routes);

function App() {
  return (
    <div className="App">
		<Layout style={{ minHeight: '100vh'}}>
			<Content id="authPage" style={{margin:0}}>
				<Row justify="center" type="flex"  style={{ minHeight: '100vh', margin: 0 }} align="middle">
					<Col style={{marginLeft: '130px'}}>
						<div className="boxFrame">
							<div>
								<p className="text-center">
									<img src="/images/logo-b.png" alt="WriterPA Logo" width="150" />
								</p>
								<Switch>
									{/* no different than other usage of
									CSSTransitionGroup, just make
									sure to pass `location` to `Route`
									so it can match the old location
									as it animates out
								*/}
									{routes.map((route, i) => (
										<RouteWithSubRoutes key={i} {...route} />
									))}
								</Switch>
								<div className="text-center">
									<a href="/privacy">Privacy</a> |{' '}
									<a href="https://twitter.com/theWriterPA" target="_blank" rel="noopener noreferrer">
										<FontAwesomeIcon icon={['fab', 'twitter']} fixedWidth />
									</a>{' '}
									<a href="https://fb.me/theWriterPA" target="_blank" rel="noopener noreferrer">
										<FontAwesomeIcon icon={['fab', 'facebook']} fixedWidth />
									</a>
								</div>
							</div>
						</div>
					</Col>
					<img
						src="/images/wpa-over.png"
						alt="WriterPA Assistant"
						style={{ position: 'relative', left: '-19px' }}
					/>
				</Row>
			</Content>
		</Layout>
    </div>
  );
}

export default App;
