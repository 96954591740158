//	eslint-disable-next-line
import React from 'react';
import { Form } from '@wpa/components/lib/Form';
import { formStateMachine } from '@wpa/state-machine';
// import { useDispatch, useSelector } from 'react-redux';
import { useMachine } from '@xstate/react';
import app from '@wpa/feathers-client';
import Logger from '@wpa/logger';

/**
 * Login form
 */

const loginFormMachine = formStateMachine({
	formAction: (context, event) =>
		new Promise((resolve, reject) => {
			return app
				.authenticate({
					email: context.fields.email,
					password: context.fields.password,
					strategy: 'local',
				})
				.then((json) => {
					if (!json.accessToken) {
						this.props.goToState('error', {
							message: 'Cannot proceed without an access token.',
						});

						return reject(
							'Cannot proceed without an access token.'
						);
					}

					//	Set session cookie
					//	For now we just dump the token into the cookie as we need it for
					//  - so we can do preview sites under sites.writerpa.com
					//	- to handle cross subdomain auth auth.* my.* sites.*
					//	@todo: expiry details?
					//	@todo: separate token from session details?
					document.cookie =
						'wpa-api=' +
						json.accessToken +
						'; Path=/; domain=.' +
						process.env.REACT_APP_PUBLIC_URL.replace(
							/https?:\/\//,
							''
						) +
						';';

					resolve();
					window.location.assign(process.env.REACT_APP_DASHBOARD_URL);
				})
				.catch((err) => Logger.error(err) || reject('Login failed.'));
		}),
});

const LoginForm = () => {
	const [state, send] = useMachine(
		loginFormMachine.withFormContext(
			{},
			{
				email: null,
				password: null,
			}
		)
	);

	return (
		<Form
			stateMachine={loginFormMachine}
			state={state}
			send={send}
			buttonRow={{
				label: 'Login',
			}}
			layout="vertical"
		>
			<Form.Item
				label="Email Address"
				name="email"
				extra={
					<small id="emailHelp">
						We'll never share your email with anyone else.
					</small>
				}
				labelCol={{}}
				wrapperCol={{}}
			>
				<Form.Input
					type="email"
					placeholder="Enter email"
					name="email"
					autoComplete="off"
				/>
			</Form.Item>
			<Form.Item
				label="Password"
				name="password"
				labelCol={{}}
				wrapperCol={{}}
			>
				<Form.Input
					type="password"
					placeholder="Password"
					name="password"
				/>
			</Form.Item>
		</Form>
	);
};

/**
 * Login screen layout
 */
export const LoginPage = () => {
	return (
		<div>
			<h1>Login</h1>
			<LoginForm></LoginForm>
		</div>
	);
};

export default LoginPage;
