
import LoginPage from './components/LoginPage.jsx';
// import PasswordPage from './pages/password/Password.jsx';
// import PasswordResetPage from './pages/passwordReset/Password.jsx';
// import RegisterPage from './pages/login/Login.jsx';
// import VerifyPage from './pages/login/Verify.jsx';


//	From most specific to least - order matters!
const routes = [
	// {
	// 	path: '/login/password/reset/:token',
	// 	component: PasswordResetPage
	// },
	// {
	// 	path: '/login/password',
	// 	component: PasswordPage
	// },
	// {
	// 	path: '/login/register',
	// 	component: RegisterPage
	// },
	// {
	// 	path: '/login/verify/:token',
	// 	component: VerifyPage
	// },
	{
		path: '/',
		component: LoginPage,
	}
];


export default routes;